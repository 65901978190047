<template>
  <div>
    <TablePrivacyTesEnte
      @getPrivacyTesEnteList="getPrivacyTesEnteList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TablePrivacyTesEnte from "@/components/components-fit/enti-aggregati/privacy/TablePrivacyTesEnte.vue";

export default defineComponent({
  name: "privacy-ente",
  components: {
    TablePrivacyTesEnte,
  },
  props: {
    ente: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Privacy Ente", [
        "Lista Ente",
        "Dettaglio Ente",
      ]);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipPrivacyTesEnte);
    const fetchRows = computed(() => store.getters.fetchRowsPrivacyTesEnte);
    const sortColumn = computed(() => store.getters.sortColumnPrivacyTesEnte);
    const sortOrder = computed(() => store.getters.sortOrderPrivacyTesEnte);

    const getPrivacyTesEnteList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: props.ente.id,
          stagione: stagione.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.ENTI_AGGREGATI_PRIVACY_LIST,
        itemName: "ente_privacy_list",
      });
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      getPrivacyTesEnteList();
    });

    const resetFilters = () => {
      store.commit("resetFiltersPrivacyTesEnte");
      getPrivacyTesEnteList();
    };
    getPrivacyTesEnteList();

    return {
      getPrivacyTesEnteList,
      resetFilters,
    };
  },
});
</script>
